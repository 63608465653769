import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import { useMediaQuery, Context as ResponsiveContext } from "react-responsive"

// Custom styles & Bootstrap
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Image from "react-bootstrap/Image"
import Carousel from "react-bootstrap/Carousel"
import styles from "./index.module.scss"

// Components
import { Layout } from "../components/Layout"
import SEO from "../components/seo"
import { ScrollDownButton } from "../components/ScrollDownButton"
import FooterCTA from "../components/FooterCTA"
import BannerShape from "../components/BannerShape"

// Content
import JSONData from "../../content/home-page.json"

// React Responsive (easy mode for SSR)
const MinWidth1116 = ({ children }) => {
  const isMin1116px = useMediaQuery({ minWidth: 1116 })
  return isMin1116px ? children : null
}

const MinWidth768 = ({ children }) => {
  const isMin768px = useMediaQuery({ minWidth: 768 })
  return isMin768px ? children : null
}

const MaxWidth767 = ({ children }) => {
  const isMax767px = useMediaQuery({ maxWidth: 767 })
  return isMax767px ? children : null
}

const IndexPage = ({ data }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [cardIndex, setCardIndex] = useState(null)

  const { seo_title, seo_description, banner_text, services } = JSONData

  const {
    seoBanner,
    heartImage,
    bannerImage,
    bannerBackgroundImage,
    coreServicesBgImage,
    coreServicesCardBgImage,
    coreServicesImage,
  } = data

  return (
    <Layout>
      <SEO
        title={seo_title}
        description={seo_description}
        image={seoBanner.childImageSharp.resize}
      />
      <Container
        fluid
        className={styles.container}
        css={css`
          @media (min-width: 576px) {
            background-image: url(${bannerBackgroundImage.publicURL});
          }
        `}
      >
        <ResponsiveContext.Provider value={{ maxWidth: 575 }}>
          <BannerShape />
        </ResponsiveContext.Provider>
        <Container>
          <Row>
            <Col>
              <h1 className={styles.header}>
                We{" "}
                <span>
                  <Img
                    className={styles.heartSpan}
                    fluid={heartImage.childImageSharp.fluid}
                    objectFit="cover"
                    alt="LOVE"
                  />
                </span>{" "}
                Bookkeeping
              </h1>
            </Col>
          </Row>
          <Row noGutters className={styles.subHeaderRow}>
            <Col xs={12} md={5} className={styles.subHeaderCol}>
              <p className={styles.subHeader}>{banner_text}</p>
              <ScrollDownButton
                name="Learn More"
                sectionId="#core-services-section"
              />
            </Col>
            <Col md={7}>
              <Img
                className={styles.bannerImg}
                fluid={bannerImage.childImageSharp.fluid}
                alt="Accounting and Bookkeeping Cebu"
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container
        id="core-services-section"
        className={styles.coreServicesContainer}
      >
        <Row className="justify-content-md-center">
          <Col xs={12}>
            <h2 className={styles.csTitle}>
              Our <span>Core Services</span>
            </h2>
          </Col>
        </Row>
      </Container>
      <Container
        className={styles.servicesContainer}
        fluid
        css={css`
          background-image: url("${coreServicesBgImage.publicURL}");
        `}
      >
        <Row className="justify-content-md-center align-items-center" noGutters>
          <MinWidth1116>
            <Col md={5}>
              <Image
                src={coreServicesImage.publicURL}
                alt="AcountablePH Core Services"
                fluid
              />
            </Col>
          </MinWidth1116>
          <Col>
            <MaxWidth767>
              <Carousel
                controls={false}
                css={css`
                  .carousel-indicators {
                    bottom: -3rem;
                    li {
                      background-color: #316546;
                      border-radius: 3px;
                      width: 6px;
                      height: 6px;
                      border-top: unset;
                      border-bottom: unset;
                      margin-left: 8px;
                      margin-right: 8px;
                    }
                  }
                `}
              >
                {services.map((item, i) => (
                  <Carousel.Item key={i} className={styles.carouselItem}>
                    <div className={styles.cardSlideContainer}>
                      <h3>{item.card_title}</h3>
                      <img
                        src={data[item.card_icon].publicURL}
                        className={styles.cardIcon}
                        alt={item.img_alt}
                      />
                      <p>{item.card_text}</p>
                      <Button
                        as={Link}
                        to={item.link_url}
                        className={styles.slideBtn}
                      >
                        Learn More
                      </Button>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </MaxWidth767>
            <MinWidth768>
              <div className={styles.cardColumn}>
                {services.map((item, i) => (
                  <div
                    onMouseEnter={() => {
                      setIsHovered(true)
                      setCardIndex(i)
                    }}
                    onMouseLeave={() => {
                      setIsHovered(false)
                      setCardIndex(null)
                    }}
                    className={
                      isHovered && cardIndex === i
                        ? `${styles.cardContainer} ${styles.cardHovered} ${
                            styles[item.card_class]
                          }`
                        : `${styles.cardContainer} ${styles[item.card_class]}`
                    }
                    css={css`
                  &:hover {
                    background-image: url('${coreServicesCardBgImage.publicURL}');
                  }`}
                    role="button"
                    tabIndex={i - 3}
                    key={i}
                  >
                    <h3>{item.card_title}</h3>
                    {isHovered && cardIndex === i ? (
                      <img
                        src={data[item.card_icon_hovered].publicURL}
                        className={styles.cardIcon}
                        alt={item.img_alt}
                      />
                    ) : (
                      <img
                        src={data[item.card_icon].publicURL}
                        className={styles.cardIcon}
                        alt={item.img_alt}
                      />
                    )}
                    <p>{item.card_text}</p>
                    <Button
                      as={Link}
                      to={item.link_url}
                      className={
                        isHovered && cardIndex === i
                          ? `${styles.cardBtn} ${styles.cardBtnHovered}`
                          : `${styles.cardBtn}`
                      }
                    >
                      Learn More
                    </Button>
                  </div>
                ))}
              </div>
            </MinWidth768>
          </Col>
        </Row>
      </Container>
      <FooterCTA />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query GET_INDEX_IMAGES {
    seoBanner: file(relativePath: { eq: "banner/seo-home-banner.png" }) {
      publicURL
      childImageSharp {
        resize(width: 1024) {
          src
          height
          width
        }
      }
    }

    bannerImage: file(relativePath: { eq: "home/banner-img.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    heartImage: file(relativePath: { eq: "home/heart.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    bannerBackgroundImage: file(relativePath: { eq: "banner/background.svg" }) {
      publicURL
    }

    coreServicesBgImage: file(
      relativePath: { eq: "home/core-services-bg.svg" }
    ) {
      publicURL
    }

    coreServicesImage: file(relativePath: { eq: "home/core-service-img.svg" }) {
      publicURL
    }

    coreServicesCardBgImage: file(relativePath: { eq: "home/card-bg.svg" }) {
      publicURL
    }

    cardAccountingIcon: file(
      relativePath: { eq: "home/accounting-bookkeeping.svg" }
    ) {
      publicURL
    }

    cardAccountingHoveredIcon: file(
      relativePath: { eq: "home/accounting-bookkeeping-hovered.svg" }
    ) {
      publicURL
    }

    cardBusinessIcon: file(
      relativePath: { eq: "home/business-registration.svg" }
    ) {
      publicURL
    }

    cardBusinessHoveredIcon: file(
      relativePath: { eq: "home/business-registration-hovered.svg" }
    ) {
      publicURL
    }

    cardPayrollIcon: file(relativePath: { eq: "home/payroll.svg" }) {
      publicURL
    }

    cardPayrollHoveredIcon: file(
      relativePath: { eq: "home/payroll-hovered.svg" }
    ) {
      publicURL
    }
  }
`
